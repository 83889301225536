import api from "./api";

const route = "/courses";

export default {
  getCourses: () => api.get(`${route}/admin/all`),
  getSingleCourse: (course_id) => api.get(`${route}/${course_id}`),
  postCourse: course => api.post(route, { ...course }),
  putCourse: course => api.put(route, { ...course }),
  deleteCourse: course_id => api.delete(`${route}/${course_id}`)
};
