import { navigate } from "@reach/router";
import React, { createContext, useContext, useReducer } from "react";
import CoursesReducer from "../reducers/CoursesReducer";
import CoursesService from "../services/CoursesService";
import {
  CREATE_COURSE,
  COURSES_RECIBIDOS,
  SET_PROPERTY_COURSE,
  SINGLE_COURSE_RECIBIDO,
} from "../types/courses";
import { SHOW_SPINNER, HIDE_SPINNER } from "../types";
import { ModalContext } from "./ModalContext";

const initialState = {
  spinner: false,
  courses: null,
  course: null,
};

export const CoursesContext = createContext(initialState);

export const CoursesProvider = ({ children }) => {
  const [state, dispatch] = useReducer(CoursesReducer, initialState);

  const { alert, success } = useContext(ModalContext);

  const getCourses = () => {
    CoursesService.getCourses().then((res) => {
      const { courses } = res.data;
      dispatch({ type: COURSES_RECIBIDOS, payload: courses });
    });
  };

  const getSingleCourse = (course_id) => {
    CoursesService.getSingleCourse(course_id)
      .then((res) => {
        const { course } = res.data;
        dispatch({ type: SINGLE_COURSE_RECIBIDO, payload: course });
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 412) {
            alert("Necesitas comprar este curso para acceder al contenido.");
            navigate(`/checkout/course/${course_id}`);
          }
        }
      });
  };

  const clearCourse = () => {
    dispatch({ type: SINGLE_COURSE_RECIBIDO, payload: null });
  };

  const createCourse = () => {
    dispatch({ type: CREATE_COURSE });
  };

  const setPropiedadCourse = (key, value) => {
    dispatch({ type: SET_PROPERTY_COURSE, payload: { key, value } });
  };

  const saveCourse = (course, callback) => {
    dispatch({ type: SHOW_SPINNER });
    let service = CoursesService.putCourse;
    if(isNaN(parseInt(course.course_id))) {
      service = CoursesService.postCourse;
    }
    service(course).then(res => {
      if(typeof callback === "function") {
        callback()
      }
      dispatch({ type: HIDE_SPINNER });
    }).catch(error => {
      dispatch({ type: HIDE_SPINNER });
      alert(error);
    });
  };

  const deleteCourse = (course_id, callback) => {
    CoursesService.deleteCourse(course_id).then(res => {
      if(typeof callback === "function") {
        callback()
      }
      success("Curso guardado.")
      dispatch({ type: HIDE_SPINNER });
    }).catch(error => {
      dispatch({ type: HIDE_SPINNER });
      alert(error);
    })
  }

  return (
    <CoursesContext.Provider
      value={{
        ...state,
        saveCourse,
        getCourses,
        clearCourse,
        createCourse,
        deleteCourse,
        getSingleCourse,
        setPropiedadCourse,
      }}
    >
      {children}
    </CoursesContext.Provider>
  );
};
