import React, { useContext, useEffect } from "react";
import PanelTitle from "../../components/global/PanelTitle";
import CourseForm from "../../components/courses/CourseForm";
import { CoursesContext } from "../../context/CoursesContext";

const AdminSingleCourseForm = ({ course_id }) => {
  const { spinner, course, getSingleCourse, setPropiedadCourse, saveCourse } =
    useContext(CoursesContext);

  useEffect(() => {
    getSingleCourse(course_id);
  }, [course_id]);

  const renderCourse = () => {
    if (course && course !== null) {
      return (
        <CourseForm
          course={course}
          spinner={spinner}
          postCourse={saveCourse}
          modifier={setPropiedadCourse}
        />
      );
    }
  };

  return (
    <div>
      <PanelTitle title="Curso" hideButton />
      <div className="card shadow">{renderCourse()}</div>
    </div>
  );
};

export default AdminSingleCourseForm;
