import React from "react";
import { formatMonto } from "../../utils";
import { Link } from "@reach/router";

const CourseRow = ({ course }) => {
  const renderPrice = () => {
    if (!isNaN(course.sale_price) && course.sale_price > 0) {
      return (
        <span>
          <span className="strike-through me-2">
            ${formatMonto(course.price)}
          </span>
          ${formatMonto(course.sale_price)}
        </span>
      );
    }
    return <span>${formatMonto(course.price)}</span>;
  };

  const renderWhitelist = () => {
    if(course.requires_whitelist) {
      return <button className="btn btn-sm btn-outline-dark">
        <i className="fa fa-users" />
      </button>
    }
  }

  return (
    <tr className="small bg-white border-bottom align-middle hover-light">
      <td>#{course.course_id}</td>
      <td>
        <Link to={`/myadmin/courses/${course.course_id}`}>{course.name}</Link>
      </td>
      <td>{renderPrice()}</td>      
      <td>
        {course.available ? (
          <i className="fa fa-check text-success" />
        ) : (
          <i className="fa fa-times text-danger" />
        )}
      </td>
      <td>
        <Link to={`/myadmin/courses/${course.course_id}/edit`} className="btn me-2 btn-sm btn-outline-dark">
          <i className="fa fa-edit" />
        </Link>
        {renderWhitelist()}
      </td>
    </tr>
  );
};

export default CourseRow;
