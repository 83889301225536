import React, { useContext, useEffect } from "react";
import CourseData from "../../components/courses/CourseData";
import PanelTitle from "../../components/global/PanelTitle";
import SectionsTable from "../../components/sections/SectionsTable";
import { CoursesContext } from "../../context/CoursesContext";
import { SectionsContext } from "../../context/SectionsContext";

const AdminSingleCourse = ({ course_id }) => {
  const { course, getSingleCourse } = useContext(CoursesContext);

  const { sections, getSectionsCourse } = useContext(SectionsContext);

  useEffect(() => {
    getSingleCourse(course_id);
    getSectionsCourse(course_id);
  }, [course_id]);

  const renderCourse = () => {
    if (course && course !== null) {
      return <CourseData course={course} />;
    }
    return <div className="spinner-border"></div>;
  };

  const renderSections = () => {
    if (Array.isArray(sections)) {
      return <SectionsTable sections={sections} />;
    }
  };

  return (
    <div className="container-fluid">
      <PanelTitle hideButton />
      <div className="card mb-3">{renderCourse()}</div>
      <div className="card mb-3">
        <h3>Secciones</h3>
        {renderSections()}
      </div>
    </div>
  );
};

export default AdminSingleCourse;
