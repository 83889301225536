import React, { useContext, useEffect } from "react";
import { LessonsContext } from "../../context/LessonsContext";
import ImageInput from "../common/ImageInput";
import { S3_ENDPOINT } from "../../utils";
import { CoachesContext } from "../../context/CoachesContext";

const LessonForm = ({ handleSubmit, handleCancel }) => {
  const { spinner, lesson, setPropertyLesson } = useContext(LessonsContext);
  const { coaches, getCoaches } = useContext(CoachesContext);

  useEffect(() => {
    getCoaches();
  }, []);

  const getSrc = () => {
    if (lesson.file && lesson.file !== null) {
      return `${S3_ENDPOINT}/${lesson.file.name}.${lesson.file.type}`;
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    handleSubmit(lesson);
  };

  const renderInstructors = () => {
    if (Array.isArray(coaches)) {
      return [
        <option key="none" value={null}>
          No Aplica
        </option>,
        ...coaches.map((coach) => (
          <option key={coach.instructor_id} value={coach.instructor_id}>
            {coach.name}
          </option>
        )),
      ];
    }
  };

  return (
    <form onSubmit={onSubmit}>
      <ImageInput
        value={getSrc()}
        label="Thumbnail"
        prop="picture"
        modifier={setPropertyLesson}
      />
      <label className="d-block">Nombre</label>
      <input
        type="text"
        value={lesson.name}
        className="form-control mb-3"
        onChange={(e) => setPropertyLesson("name", e.target.value)}
      />
      <label className="d-block">Descripción</label>
      <input
        type="text"
        value={lesson.description}
        className="form-control mb-3"
        onChange={(e) => setPropertyLesson("description", e.target.value)}
      />
      <label className="d-block">Instructor</label>
      <select
        value={lesson.instructor_id}
        className="form-control mb-3"
        onChange={(e) => setPropertyLesson("instructor_id", e.target.value)}
      >
        {renderInstructors()}
      </select>
      <label className="d-block">Nivel</label>
      <select
        value={lesson.level}
        className="form-control mb-3"
        onChange={(e) => setPropertyLesson("level", e.target.value)}
      >
        <option value="">No Aplica</option>
        <option>Principiante</option>
        <option>Intermedio</option>
        <option>Avanzado</option>
      </select>
      <div className="row">
        <div className="col-6">
          <button type="submit" disabled={spinner} className="btn btn-primary">
            {spinner ? <div className="spinner-border"></div> : "Guardar"}
          </button>
        </div>
        <div className="col-6 text-end">
          <button
            type="button"
            className="btn btn-link text-muted"
            onClick={handleCancel}
          >
            Cancelar
          </button>
        </div>
      </div>
    </form>
  );
};

export default LessonForm;
