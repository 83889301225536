import React from "react";

const FilterForm = ({ filters, values, setFilter, section_id }) => {
  section_id = parseInt(section_id);

  const renderOptions = (key) => {
    let options = values[key];
    if (Array.isArray(options)) {
      return [
        <option key="all" value={null}>
          Todo
        </option>,
        ...options.map((option, index) => (
          <option key={index} value={option.value}>
            {option.name}
          </option>
        )),
      ];
    }
  };

  const renderCustomFilters = () => {
    if (section_id === 4) {
      const { author, level, instructor_id } = filters;
      return (
        <>
          <div className="col-12 col-md-2 mb-2">
            <label>Artista</label>
            <select
              value={author === null ? "" : author}
              className="form-control"
              onChange={(e) => setFilter("author", e.target.value)}
            >
              {renderOptions("author")}
            </select>
          </div>
          <div className="col-12 col-md-2 mb-2">
            <label>Nivel</label>
            <select
              value={level === null ? "" : level}
              className="form-control"
              onChange={(e) => setFilter("level", e.target.value)}
            >
              {renderOptions("level")}
            </select>
          </div>
          <div className="col-12 col-md-2 mb-2">
            <label>Coach</label>
            <select
              value={instructor_id === null ? "" : instructor_id}
              className="form-control"
              onChange={(e) => setFilter("instructor_id", e.target.value)}
            >
              {renderOptions("instructor_id")}
            </select>
          </div>
        </>
      );
    }
  };

  return (
    <div className="row my-2">
      <div
        className={
          section_id === 4 ? "col-12 col-md-4 mb-2" : "container-fluid"
        }
      >
        <label>Palabra Clave</label>
        <input
          type="text"
          className="form-control"
          value={filters.query}
          placeholder="Buscar por nombre de clase..."
          onChange={(e) => setFilter("query", e.target.value)}
        />
      </div>
      {renderCustomFilters()}
    </div>
  );
};

export default FilterForm;
