import React, { useContext, useEffect, useState } from "react";
import PanelTitle from "../../components/global/PanelTitle";
import { CoursesContext } from "../../context/CoursesContext";
import { CustomerContext } from "../../context/CustomerContext";
import UserCourseStatusTable from "../../components/users/UserCourseStatusTable";

const AdminUsersCourse = ({ course_id }) => {
  const [query, setQuery] = useState("");
  const [status, setStatus] = useState("");
  const [course, setCourse] = useState(null);

  const { courses, getCourses } = useContext(CoursesContext);

  const { customers, getCustomersCourse, clearAllCustomers } =
    useContext(CustomerContext);

  useEffect(() => {
    getCourses();
    return clearAllCustomers;
  }, []);

  useEffect(() => {
    if (course !== null) {
      fetchPurchases();
    }
  }, [course]);

  useEffect(() => {
    if (Array.isArray(courses) && course === null && course_id) {
      const currentCourse = courses.find(
        (course) => parseInt(course.course_id) === parseInt(course_id)
      );
      setCourse(currentCourse);
    }
  }, [courses, course_id]);

  const fetchPurchases = () => {
    getCustomersCourse(course.course_id);
  };

  /*
  const handleChange = (e) => {
    const { value } = e.target;
    const currentCourse = courses.find(
      (course) => parseInt(course.course_id) === parseInt(value)
    );
    setCourse(currentCourse);
  };

  const renderCourses = () => {
    if (Array.isArray(courses)) {
      return [
        <option value="" key="default">
          Todos los Cursos
        </option>,
        ...courses.map((course) => (
          <option key={course.course_id} value={course.course_id}>
            {course.name}
          </option>
        )),
      ];
    }
  };
  */

  return (
    <div className="container-fluid">
      <PanelTitle title="Instructoras" />
      <div className="container-fluid px-0">
        <input
          type="text"
          value={query}
          className="form-control mb-3"
          onChange={(e) => setQuery(e.target.value)}
          placeholder="Buscar por nombre o correo electrónico..."
        />
      </div>
      <div className="card no-scale p-3 mb-3 shadow-sm">
        <div className="row align-items-center mb-3">
          <div className="col-12 col-md-6">
            <button
              className={`btn btn-sm btn-${
                status === "" ? "primary" : "light"
              } border br-0`}
              onClick={() => setStatus("")}
            >
              Todo
            </button>
            <button
              className={`btn btn-sm btn-${
                status ? "primary" : "light"
              } border br-0`}
              onClick={() => setStatus(true)}
            >
              Activos
            </button>
            <button
              className={`btn btn-sm btn-${
                !status && status !== "" ? "primary" : "light"
              } border br-0`}
              onClick={() => setStatus(false)}
            >
              Inactivos
            </button>
          </div>
          <div className="col-12 col-md-6">
            {/*<select
              value={course !== null ? course.course_id : ""}
              className="form-control"
              onChange={handleChange}
            >
              {renderCourses()}
            </select>*/}
          </div>
        </div>
        {Array.isArray(customers) && (
          <UserCourseStatusTable
            query={query}
            status={status}
            course={course}
            users={customers}
          />
        )}
      </div>
    </div>
  );
};

export default AdminUsersCourse;
