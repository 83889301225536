import React, { useContext } from "react";
import { FiltersContext } from "../../context/FiltersContext";
import LessonCard from "./LessonCard";

const LessonsGrid = ({ lessons, handleEdit }) => {
  const { level, query, author, instructor_id } = useContext(FiltersContext);

  const renderLessons = () => {
    if (Array.isArray(lessons)) {
      if (lessons.length === 0) {
        return <p>No hay contenido para esta sección.</p>;
      }
      let lessonsRender = [...lessons];
      if (query !== null && query !== "") {
        let currentQuery = String(query).toLowerCase();
        lessonsRender = lessonsRender.filter((lesson) => {
          let name = String(lesson.name).toLowerCase();
          let description = String(lesson.description).toLowerCase();
          return (
            name.startsWith(currentQuery) ||
            name.includes(currentQuery) ||
            description.startsWith(currentQuery) ||
            description.includes(currentQuery)
          );
        });
      }
      if (author !== null) {
        lessonsRender = lessonsRender.filter(
          (lesson) => lesson.author === author
        );
      }
      if (level !== null) {
        lessonsRender = lessonsRender.filter(
          (lesson) => lesson.level === level
        );
      }
      if (instructor_id !== null) {
        lessonsRender = lessonsRender.filter(
          (lesson) => parseInt(lesson.instructor_id) === parseInt(instructor_id)
        );
      }
      return lessonsRender.map((lesson) => (
        <LessonCard
          lesson={lesson}
          key={lesson.lesson_id}
          handleEdit={handleEdit}
        />
      ));
    }
  };

  return <div className="row mx-0">{renderLessons()}</div>;
};

export default LessonsGrid;
