import React, { useContext, useEffect, useState } from "react";
import { PaymentMethodsContext } from "../../context/PaymentMethodsContext";
import Switch from "react-switch";

const AddPurchaseForm = ({ customer, paquetes, extenderAcceso }) => {
  const [dias, setDias] = useState(0);
  const [gift, setGift] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [paquete, setPaquete] = useState(null);
  const [packageID, setpackageID] = useState(null);

  const { payment_methods, getPaymentMethods } = useContext(
    PaymentMethodsContext
  );

  useEffect(() => {
    getPaymentMethods();
  }, []);

  useEffect(() => {
    if (paquetes && paquetes !== null && paquete === null) {
      setPaquete(paquetes[0]);
      setpackageID(paquetes[0].course_id);
      setDias(paquetes[0].expiration_days);
    }
  }, [paquetes]);

  useEffect(() => {
    if (paquete !== null) {
      setPaymentMethod(1);
    }
  }, [paquete]);

  const handleSubmit = (e) => {
    e.preventDefault();
    extenderAcceso(customer.user_id, packageID, dias, gift, paymentMethod);
  };

  const renderPaquetes = () => {
    if (paquetes && paquetes !== null) {
      return paquetes.map((paquete) => (
        <option key={paquete.course_id} value={paquete.course_id}>
          {paquete.name}
        </option>
      ));
    }
  };

  const renderPaymentMethods = () => {
    if (Array.isArray(payment_methods)) {
      return payment_methods.map((payment_method) => (
        <option
          key={payment_method.payment_method_id}
          value={payment_method.payment_method_id}
        >
          {payment_method.name}
        </option>
      ));
    }
  };

  return (
    <div className="container-fluid px-0">
      <form onSubmit={handleSubmit}>
        <label>Paquete</label>
        <select
          className="form-control mb-3"
          onChange={(e) => setpackageID(e.target.value)}
        >
          {renderPaquetes()}
        </select>
        <label>Número de Días</label>
        <input
          type="number"
          className="form-control mb-3"
          value={dias}
          onChange={(e) => setDias(e.target.value)}
        />
        <label className="d-block">¿Es Regalo?</label>
        <Switch
          checked={gift}
          className="d-block mt-1 mb-3"
          onChange={(checked) => setGift(checked)}
        />
        {!gift && (
          <>
            <label className="d-block">Método de Pago</label>
            <select
              className="form-control mb-3"
              value={paymentMethod}
              onChange={(e) => setPaymentMethod(e.target.value)}
            >
              {renderPaymentMethods()}
            </select>
          </>
        )}
        <input type="submit" className="btn btn-dark" value="Extender Acceso" />
      </form>
    </div>
  );
};

export default AddPurchaseForm;
