import React, { useContext, useEffect, useState } from "react";
import { CustomerContext } from "../../context/CustomerContext";
import { UserContext } from "../../context/UserContext";
import { S3_ENDPOINT, formatMonto, getTotalCompras } from "../../utils";
import { navigate } from "@reach/router";
import moment from "moment";
import { ModalContext } from "../../context/ModalContext";

const CustomerInfo = ({ customer }) => {
  const [copied, setCopied] = useState(false);
  const { recoverPassword } = useContext(UserContext);

  const { link, getPasswordResetLink } = useContext(CustomerContext);

  const { success } = useContext(ModalContext);

  useEffect(() => {
    if (link !== null) {
      let input = document.createElement("input");
      input.value = link;
      input.id = "copy-input";
      document.body.appendChild(input);
      var copyText = document.getElementById("copy-input");
      copyText.select();
      copyText.setSelectionRange(0, 99999);
      document.execCommand("copy");
      navigator.clipboard.writeText(copyText.value).then(() => {
        setCopied(true);
      });
      input.remove();
    }
  }, [link]);

  useEffect(() => {
    if (copied) {
      success("Enlace copiado al portapapeles.");
    }
  }, [copied]);

  const renderOnlineAccess = () => {
    if (customer.has_online !== undefined) {
      return (
        <div className="row mb-3">
          <div className="col-6">Acceso a Clases Online</div>
          <div className="col-6">
            {customer.has_online ? (
              <span className="badge badge-pill bg-success">Activo</span>
            ) : (
              <span className="badge badge-pill bg-secondary">Inactivo</span>
            )}
          </div>
        </div>
      );
    }
  };

  const getSrc = () => {
    if (customer.file && customer.file !== null) {
      return `${S3_ENDPOINT}/files/${customer.file.name}.${customer.file.type}`;
    }
    return "/images/logo_recortado.png";
  };

  const {
    user_id,
    name,
    last_name,
    birthdate,
    email,
    phone,
    city,
    signup_reason,
    instagram_account,
  } = customer;

  return (
    <>
      <div className="row">
        <div className="col-12 col-md-3">
          <img src={getSrc()} className="m-auto d-block w-100 profile-image" />
        </div>
        <div className="col-12 col-md-9">
          <h4 className="mb-3 pb-3 border-bottom">
            {name} {last_name}
          </h4>
          <div className="row">
            <div className="col-1">
              <i className="fa fa-birthday-cake"></i>
            </div>
            <div className="col-11">
              {moment(birthdate).format("DD MMM YYYY")}
            </div>
          </div>
          <div className="row">
            <div className="col-1">
              <i className="fa fa-envelope"></i>
            </div>
            <div className="col-11">{email}</div>
          </div>
          <div className="row">
            <div className="col-1">
              <i className="fa fa-phone"></i>
            </div>
            <div className="col-11">{phone}</div>
          </div>
          <div className="row">
            <div className="col-1">@</div>
            <div className="col-11">{instagram_account}</div>
          </div>
          <div className="row">
            <div className="col-1">
              <i className="fa fa-map-pin"></i>
            </div>
            <div className="col-11">{city}</div>
          </div>
          <div className="row">
            <div className="col-1">
              <i className="fa fa-question"></i>
            </div>
            <div className="col-11">{signup_reason}</div>
          </div>
          <div className="row mt-3">
            <div className="container-fluid">
              <button
                className="btn btn-outline-dark my-1 me-2"
                onClick={() => navigate(`/myadmin/customer/${user_id}/edit`)}
              >
                <i className="fa fa-edit me-2"></i> Editar Información
              </button>
            </div>
            <h4 className="mt-3 mb-2">Reestablecer Contraseña</h4>
            <div className="row">
              <div className="col-6">
                <button
                  className="btn btn-outline-dark me-2 my-1"
                  onClick={() => recoverPassword(customer.email)}
                >
                  <i className="fa fa-envelope me-2"></i> Enviar Correo
                  Contraseña
                </button>
                <button
                  className="btn btn-outline-dark me-2 my-1"
                  onClick={() => getPasswordResetLink(customer.email)}
                >
                  <i className="fa fa-link me-2"></i> Generar Link
                </button>
              </div>
              <div className="col-6"></div>
            </div>
          </div>
          <div className="row mt-4">
            <h4>
              Compras Totales: {"$"}
              {formatMonto(getTotalCompras(customer))} MXN
            </h4>
          </div>
          {renderOnlineAccess()}
        </div>
      </div>
    </>
  );
};

export default CustomerInfo;
