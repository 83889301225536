import React, { createContext, useContext, useReducer } from "react";
import CoursePurchaseReducer from "../reducers/CoursePurchaseReducer";
import CoursePurchaseService from "../services/CoursePurchaseService";
import { SET_COURSE_PURCHASE } from "../types";
import { hideModal } from "../utils";
import { CustomerContext } from "./CustomerContext";
import { ModalContext } from "./ModalContext";

const initialState = {
  course_purchases: null,
  course_purchase: null,
};

export const CoursePurchaseContext = createContext(initialState);

export const CoursePurchaseProvider = ({ children }) => {
  const [state, dispatch] = useReducer(CoursePurchaseReducer, initialState);

  const { success, alert } = useContext(ModalContext);

  const { getCustomer } = useContext(CustomerContext);

  const setCoursePurchase = (course_purchase) => {
    dispatch({ type: SET_COURSE_PURCHASE, payload: course_purchase });
  };

  const updateCoursePurchase = (course_purchase, customer_id) => {
    CoursePurchaseService.putCoursePurchase(course_purchase)
      .then(() => {
        success("Acceso guardado.");
        getCustomer(customer_id);
        hideModal();
      })
      .catch(alert);
  };

  return (
    <CoursePurchaseContext.Provider
      value={{ ...state, setCoursePurchase, updateCoursePurchase }}
    >
      {children}
    </CoursePurchaseContext.Provider>
  );
};
