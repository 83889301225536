import React, { useContext, useEffect } from "react";
import { CustomerContext } from "../../context/CustomerContext";
import { ModalContext } from "../../context/ModalContext";
import { UserContext } from "../../context/UserContext";
import CustomerInfo from "../../components/customers/CustomerInfo";
import { hideModal } from "../../utils";
import PurchasesTable from "../../components/purchases/PurchasesTable";
import InvoicesTable from "../../components/invoices/InvoicesTable";
import PanelTitle from "../../components/global/PanelTitle";
import AddPurchaseForm from "../../components/purchases/AddPurchaseForm";
import CoursePurchasesTable from "../../components/course_purchases/CoursePurchaseTable";
import InvoiceForm from "../../components/invoices/InvoiceForm";
import { InvoicesContext } from "../../context/InvoicesContext";
import { CoursesContext } from "../../context/CoursesContext";

const AdminSingleUsuario = ({ customer_id }) => {
  const {
    customer,
    getCustomer,
    clearCustomer,
    extenderAcceso,
    deleteCustomer,
  } = useContext(CustomerContext);
  const { courses, getCourses } = useContext(CoursesContext);

  const { createInvoice } = useContext(InvoicesContext);

  const { modalComponent } = useContext(ModalContext);

  const { user } = useContext(UserContext);

  useEffect(() => {
    getCustomer(customer_id);
    getCourses();
    return clearCustomer;
  }, []);

  const addInvoice = () => {
    createInvoice();
    modalComponent("Agregar Cargo", <InvoiceForm />);
  };

  const toggleModal = () => {
    modalComponent(
      "Agregar Acceso",
      <AddPurchaseForm
        customer={customer}
        extenderAcceso={extenderAcceso}
        paquetes={courses}
      />
    );
  };

  const getCustomerCourses = () => {
    let courses = new Set();
    if (customer !== null) {
      customer.purchases.forEach(({ course_purchase }) => {
        if (course_purchase !== null) {
          courses.add(course_purchase.course_id);
        }
      });
    }
    return Array.from(courses);
  };

  const confirmDelete = () => {
    modalComponent(
      "Precaución",
      <div>
        <p>
          ¿Estás segura que deseas eliminar el cliente {customer.name}{" "}
          {customer.last_name} con el correo {customer.email}?
        </p>
        <p>Esta acción NO puede deshacerse.</p>
        <div className="row">
          <div className="col-6">
            <button
              className="btn btn-danger"
              onClick={() => deleteCustomer(customer_id)}
            >
              <i className="fa fa-trash"></i> Eliminar Cliente
            </button>
          </div>
          <div className="col-6 text-right">
            <button className="btn btn-link text-secondary" onClick={hideModal}>
              Cancelar
            </button>
          </div>
        </div>
      </div>
    );
  };

  const renderUsuario = () => {
    if (customer && customer !== null) {
      return <CustomerInfo customer={customer} />;
    }
    return <div className="spinner-border"></div>;
  };

  return (
    <div className="container-fluid">
      <div className="row pb-2 border-bottom mx-0 mb-3 align-items-center">
        <div className="col col-md-6 ps-0">
          <h1>Cliente</h1>
        </div>
        <div className="col col-md-6 pe-0 text-end"></div>
      </div>
      <div className="card no-scale mb-3">{renderUsuario()}</div>
      <div className="card no-scale p-3">
        <PanelTitle title="Cursos" hideButton />
        <CoursePurchasesTable
          customer_id={customer_id}
          course_purchases={getCustomerCourses()}
          courses={customer !== null ? customer.course_purchases : []}
        />
      </div>
      <div className="card p-3 pb-1 no-scale my-3">
        <PanelTitle title="Compras" onClick={toggleModal} />
        <PurchasesTable
          user={user}
          purchases={customer !== null ? customer.purchases : []}
        />
      </div>
      <div className="card p-3 pb-1 no-scale my-3">
        <PanelTitle title="Cargos" onClick={addInvoice} />
        <InvoicesTable
          user={user}
          customer_id={customer_id}
          invoices={customer !== null ? customer.invoices : []}
        />
      </div>
      <button className="btn btn-outline-danger mt-5" onClick={confirmDelete}>
        <i className="fa fa-trash"></i> Eliminar Cliente
      </button>
    </div>
  );
};

export default AdminSingleUsuario;
