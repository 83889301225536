import React from "react";
import { Router } from "@reach/router";
import AdminClientes from "./admin/AdminClientes";
import AdminPaquetes from "./admin/AdminPaquetes";
import AdminPaqueteForm from "./admin/AdminPaqueteForm";
import AdminClases from "./admin/AdminClases";
import AdminSesiones from "./admin/AdminSesiones";
import AdminAsistentes from "./admin/AdminAsistentes";
import AdminLocations from "./admin/AdminLocations";
import AdminCoaches from "./admin/AdminCoaches";
import AdminSingleUsuario from "./admin/AdminSingleUsuario";
import AdminHome from "./admin/AdminHome";
import AdminClassTypes from "./admin/AdminClassTypes";
import AdminDescuentos from "./admin/AdminDescuentos";
import AdminEspeciales from "./admin/AdminEspeciales";
import AdminAsistentesEspeciales from "./admin/AdminAsistentesEspeciales";
import AdminCustomerForm from "./admin/AdminCustomerForm";
import AdminUsuarios from "./admin/AdminUsuarios";
import AdminInvoices from "./admin/AdminInvoices";
import AdminPurchases from "./admin/AdminPurchases";
import AdminVideos from "./admin/AdminVideos";
import AdminVideoForm from "./admin/AdminVideoForm";
import AdminClassCategories from "./admin/AdminClassCategories";
import AdminCourses from "./admin/AdminCourses";
import AdminSingleCourse from "./admin/AdminSingleCourse";
import AdminSingleSection from "./admin/AdminSingleSection";
import AdminSingleLesson from "./admin/AdminSingleLesson";
import AdminUsersCourse from "./admin/AdminUsersCourse";
import AdminSingleCourseForm from "./admin/AdminSingleCourseForm";

const Admin = () => {
  return (
    <Router>
      <AdminClientes path="/customers" />
      <AdminCustomerForm path="/customer/:customer_id/edit" />
      <AdminSingleUsuario path="/customer/:customer_id" />
      {/*
      <AdminPaquetes path="/paquetes" />
      <AdminPaqueteForm path="/paquetes/:package_class_id" />
      <AdminClases path="/clases/*" default />
      <AdminSesiones path="/asistentes" />
      <AdminAsistentes path="/asistentes/:single_class_id" />
      <AdminLocations path="/ubicaciones" />
      <AdminClassTypes path="/tipos" />
      <AdminHome path="/home" />
      <AdminDescuentos path="/descuentos" />
      <AdminEspeciales path="/especiales" />
      <AdminAsistentesEspeciales path="/especiales/:class_package_id" />
      */}
      <AdminCoaches path="/instructors" />
      <AdminPurchases path="/orders" default />
      <AdminUsuarios path="/users" />
      <AdminInvoices path="/subscriptions" />
      <AdminVideos path="/videos" />
      <AdminCourses path="/courses" />
      <AdminSingleCourseForm path="/courses/:course_id/edit" />
      <AdminSingleCourse path="/courses/:course_id" />
      <AdminSingleSection path="/sections/:section_id" />
      <AdminSingleLesson path="/lessons/:lesson_id" />
      <AdminUsersCourse path="/customers/course/:course_id" />
      {/*}
      <AdminLocations path="/locations" />
      <AdminVideoForm path="/videos/:video_id" />
      <AdminClassCategories path="/class_categories" />
      */}
    </Router>
  );
};

export default Admin;
