import React from "react";
import Switch from "react-switch";
import { Link } from "@reach/router";
import { getValue } from "../../utils";
import ToggleOption from "../common/ToggleOption";
import SpecialEventOptions from "./SpecialEventOptions";
import SubscriptionOptions from "./SubscriptionOptions";

const CourseForm = ({ spinner, modifier, postCourse, course }) => {
  const handleSubmit = (e) => {
    e.preventDefault();
    postCourse(course);
  };

  const addDefaultPeriod = () => {
    modifier("subscription_period", "month");
    modifier("subscription_interval", "1");
  };

  const renderSubscriptionOptions = () => {
    if (getValue(course, "is_subscription", "boolean")) {
      return <SubscriptionOptions course={course} modifier={modifier} />;
    }
  };

  const renderSpeciaEventOptions = () => {
    if (getValue(course, "is_special_event", "boolean")) {
      return <SpecialEventOptions course={course} modifier={modifier} />;
    }
  };

  return (
    <div className="container-fluid px-0">
      <form onSubmit={handleSubmit}>
        <ToggleOption
          label="Disponible"
          value={getValue(course, "available", "boolean")}
          modifier={(checked) => modifier("available", checked)}
        />
        <ToggleOption
          label="¿Requiere Lista de Aprobación?"
          value={getValue(course, "requires_whitelist", "boolean")}
          modifier={(checked) => modifier("requires_whitelist", checked)}
        />
        <label>Nombre</label>
        <input
          type="text"
          className="form-control mb-3"
          value={getValue(course, "name")}
          onChange={(e) => modifier("name", e.target.value)}
        />
        <label>Descripción Corta</label>
        <input
          type="text"
          className="form-control mb-3"
          value={getValue(course, "short_description")}
          onChange={(e) => modifier("short_description", e.target.value)}
        />
        <label>Descripción</label>
        <input
          type="text"
          className="form-control mb-3"
          value={getValue(course, "description")}
          onChange={(e) => modifier("description", e.target.value)}
        />
        <div className="row mb-3">
          <div className="col-6">
            <label>Badge</label>
            <input
              type="number"
              className="form-control mb-3"
              placeholder="Dejar en blanco para desactivar"
              value={getValue(course, "badge")}
              onChange={(e) => modifier("badge", e.target.value)}
            />
          </div>
          <div className="col-6">
            <label>URL</label>
            <input
              type="text"
              className="form-control mb-3"
              value={getValue(course, "slug")}
              onChange={(e) => modifier("slug", e.target.value)}
            />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-6">
            <label>Precio</label>
            <input
              type="number"
              className="form-control"
              value={getValue(course, "price")}
              onChange={(e) => modifier("price", e.target.value)}
            />
          </div>
          <div className="col-6">
            <label>Precio de Oferta</label>
            <input
              type="text"
              className="form-control mb-3"
              value={getValue(course, "sale_price")}
              onChange={(e) => modifier("sale_price", e.target.value)}
            />
          </div>
        </div>
        <label>Información de Precio</label>
        <input
          type="text"
          className="form-control mb-3"
          value={getValue(course, "price_info")}
          onChange={(e) => modifier("price_info", e.target.value)}
        />
        {renderSpeciaEventOptions()}
        {!course.is_special_event && (
          <div>
            <h4 className="border-bottom pb-2">Suscripción</h4>
            <div className="row mb-2">
              <div className="col-6">
                <label>¿Es cargo recurrente?</label>
              </div>
              <div className="col-6">
                <Switch
                  checked={getValue(course, "is_subscription", "boolean")}
                  onChange={(checked) => {
                    modifier("is_subscription", checked);
                    if (checked) addDefaultPeriod();
                  }}
                />
              </div>
            </div>
          </div>
        )}
        {renderSubscriptionOptions()}
        <div className="row mb-3">
          <div className="col-6">
            <label>Límite de Clientes</label>
            <input
              type="number"
              className="form-control mb-3"
              value={getValue(course, "customer_limit")}
              placeholder="Dejar en blanco para desactivar"
              onChange={(e) => modifier("customer_limit", e.target.value)}
            />
          </div>
          <div className="col-6">
            <label>Límite por Cliente</label>
            <input
              type="number"
              className="form-control mb-3"
              placeholder="Dejar en blanco para desactivar"
              value={getValue(course, "limit_per_customer")}
              onChange={(e) => modifier("limit_per_customer", e.target.value)}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-6">
            <button type="submit" className="btn btn-primary">
              {spinner ? <div className="spinner-border"></div> : "Guardar"}
            </button>
          </div>
          <div className="col-6 text-right">
            <Link to="/myadmin/videos" className="btn btn-link text-secondary">
              Cancelar
            </Link>
          </div>
        </div>
      </form>
    </div>
  );
};

export default CourseForm;
