import React from "react";
import { S3_ENDPOINT } from "../../utils";
import "./media.css";

const SingleMedia = ({ media, handleEdit, handleDelete }) => {
  const getClassName = () => {
    const { type } = media;
    if (["vimeo", "youtube"].includes(type)) {
      return "container-fluid p-3";
    }
    return "col-6 col-sm-6 col-xl-3 mb-3";
  };
  const renderMedia = () => {
    const video_source = media.src;
    const { type } = media;
    if (type === "vimeo") {
      return (
        <iframe
          className="video-iframe"
          src={`https://player.vimeo.com/video/${video_source}`}
          allowFullScreen
        ></iframe>
      );
    }
    if (type === "youtube") {
      const source =
        "https://www.youtube.com/embed/" + video_source.split("v=")[1];
      return (
        <iframe
          src={source}
          title="YouTube video player"
          className="mw-100 w-100 shadow"
          height="500px"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        ></iframe>
      );
    }
    if (type === "drive") {
      return (
        <iframe
          src={video_source}
          className="mw-100 w-100"
          height="auto"
          width="100%"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        ></iframe>
      );
    }
    if (type === "mp4") {
      return (
        <video className="lesson-media mw-100 w-100 mb-3" controls>
          <source
            src={`${S3_ENDPOINT}/${media.file.name}.${media.file.type}`}
            type="video/mp4"
          />
        </video>
      );
    }
    if (media.file && media.file !== null) {
      if (
        ["png", "jpg", "jpeg", "gif"].includes(
          String(media.file.type).toLowerCase()
        )
      ) {
        return (
          <img
            src={`${S3_ENDPOINT}/${media.file.name}.${media.file.type}`}
            className="mw-100 w-100 p-3 border lesson-media"
          />
        );
      }
      return (
        <a
          href={`${S3_ENDPOINT}/${media.file.name}.${media.file.type}`}
          target="_blank"
          className="p-3 no-decoration text-dark"
        >
          <img
            src="https://latinacerts.s3.us-west-1.amazonaws.com/Icon-Marketing.png"
            className="lesson-media-thumbnail"
          />
          <h4 className="mt-4">{media.name}</h4>
          <p className="small">
            {media.file.name}.{media.file.type}
          </p>
        </a>
      );
    }
  };

  const renderDownload = () => {
    if (media.type === "file" || media.type === null) {
      return (
        <a
          href={`${S3_ENDPOINT}/${media.name}.${media.type}`}
          className="btn btn-sm btn-outline-dark me-1"
          download
        >
          <i className="fa fa-download"></i>
        </a>
      );
    }
  };

  return (
    <div className={`lesson-media ${getClassName()}`}>
      <div className="border shadow-sm">
        <div className="lesson-media-content bg-white row mx-0 align-items-center">
          {renderMedia()}
        </div>
        <div className="row mx-0 bg-light border">
          <div className="lesson-media-buttons py-2">
            {renderDownload()}
            <button
              className="btn btn-sm btn-outline-dark me-1"
              onClick={() => handleEdit(media)}
            >
              <i className="fa fa-edit"></i>
            </button>
            <button
              className="btn btn-sm btn-outline-danger"
              onClick={() => handleDelete(media)}
            >
              <i className="fa fa-trash"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleMedia;
