import React from "react";
import SectionRow from "./SectionRow";

const SectionsTable = ({ sections }) => {
  const renderSections = () => {
    if (Array.isArray(sections)) {
      if (sections.length === 0) {
        return (
          <tr>
            <td colSpan={5}>No hay secciones para este curso.</td>
          </tr>
        );
      }
      return sections.map((section) => (
        <SectionRow section={section} key={section.section_id} />
      ));
    }
  };

  return (
    <div className="table-responsive">
      <table className="table border">
        <thead className="bg-light border bold small">
          <tr>
            <td>#ID</td>
            <td>Banner</td>
            <td>Nombre</td>
            <td className="wide">Descripción</td>
          </tr>
        </thead>
        <tbody>{renderSections()}</tbody>
      </table>
    </div>
  );
};

export default SectionsTable;
