import React from "react";
import { S3_ENDPOINT } from "../../utils";

const CourseData = ({ course }) => {
  const {
    file,
    name,
    description,
  } = course;

  const getSrc = () => {
    let src = "latina-cert-logo.png";
    if (file && file !== null) {
      src = `${file.name}.${file.type}`;
    }
    return `${S3_ENDPOINT}/${src}`;
  };

  return (
    <div className="container-fluid px-0">
      <div className="row mb-3 align-items-center mx-0">
        <div className="col-12 col-md-4 ps-0">
          <img
            src={getSrc()}
            className="w-100 br-10 profile-image"
            alt="Course Thumbnail"
          />
        </div>
        <div className="col-12 col-md-8">
          <h4 className="pb-3 border-bottom">{name}</h4>
          <div className="mb-2">{description}</div>
        </div>
      </div>
    </div>
  );
};

export default CourseData;
