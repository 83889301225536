import React, { useContext, useEffect } from "react";
import PanelTitle from "../../components/global/PanelTitle";
import SectionData from "../../components/sections/SectionData";
import { LessonsContext } from "../../context/LessonsContext";
import { SectionsContext } from "../../context/SectionsContext";
import LessonsGrid from "../../components/lessons/LessonsGrid";
import { ModalContext } from "../../context/ModalContext";
import LessonForm from "../../components/lessons/LessonForm";
import { getFilters, hideModal } from "../../utils";
import FilterSection from "../../components/global/FilterSection";
import { FiltersContext } from "../../context/FiltersContext";

const AdminSingleSection = ({ section_id }) => {
  const { section, getSingleSection, clearSingleSection } =
    useContext(SectionsContext);
  const { setFilterValues } = useContext(FiltersContext);
  const {
    lessons,
    postLesson,
    clearLesson,
    clearLessons,
    createLesson,
    selectLesson,
    getSectionLessons,
  } = useContext(LessonsContext);

  const { modalComponent, clearModal } = useContext(ModalContext);

  useEffect(() => {
    clearLessons();
    clearSingleSection();
    getSingleSection(section_id);
    getSectionLessons(section_id);
  }, [section_id]);

  useEffect(() => {
    if (Array.isArray(lessons) && parseInt(section_id) === 4) {
      const { instructors, levels, authors } = getFilters(lessons);
      setFilterValues("instructor_id", instructors);
      setFilterValues("author", authors);
      setFilterValues("level", levels);
    }
  }, [lessons]);

  const handleCancel = () => {
    hideModal();
    clearModal();
    clearLesson();
  };

  const handleSubmit = (lesson) => {
    lesson.section_id = section_id;
    postLesson(lesson);
  };

  const handleEditLesson = (lesson) => {
    selectLesson(lesson);
    modalComponent(
      "Editar Lección",
      <LessonForm handleSubmit={handleSubmit} handleCancel={handleCancel} />
    );
  };

  const handleCreateLesson = () => {
    createLesson();
    modalComponent(
      "Crear Lección",
      <LessonForm handleSubmit={handleSubmit} handleCancel={handleCancel} />
    );
  };

  const renderSection = () => {
    if (section && section !== null) {
      return <SectionData section={section} />;
    }
    return <div className="spinner-border"></div>;
  };

  const renderLessons = () => {
    if (Array.isArray(lessons)) {
      return <LessonsGrid lessons={lessons} handleEdit={handleEditLesson} />;
    }
  };

  return (
    <div className="container-fluid">
      <div className="card px-0 py-0 mb-3">{renderSection()}</div>
      <div className="container-fluid">
        <PanelTitle title="Contenido" onClick={handleCreateLesson} />
      </div>
      <FilterSection section_id={section_id} />
      {renderLessons()}
    </div>
  );
};

export default AdminSingleSection;
