import React from "react";
import UserCourseStatusRow from "./UserCourseStatusRow";

const UserCourseStatusTable = ({ query, status, course, users }) => {
  const renderUsers = () => {
    if (Array.isArray(users) && course !== null) {
      if (users.length === 0) {
        return (
          <tr>
            <td colSpan={5}>No hay usuarios con ese contenido comprado.</td>
          </tr>
        );
      }
      let usersRender = users;
      if (query && query !== "") {
        let currentQuery = String(query).toLowerCase();
        usersRender = usersRender.filter((user) => {
          let name = String(user.name).toLowerCase();
          let email = String(user.email).toLowerCase();
          let phone = String(user.phone).toLowerCase();
          return (
            name.includes(currentQuery) ||
            email.includes(currentQuery) ||
            phone.includes(currentQuery)
          );
        });
      }
      if (status !== "") {
        usersRender = usersRender.filter((user) => user.has_access === status);
      }
      return usersRender.map((user) => (
        <UserCourseStatusRow user={user} course={course} key={user.user_id} />
      ));
    }
  };

  return (
    <div className="table-responsive">
      <table className="table border">
        <thead className="bg-light border bold small">
          <tr>
            <td>Cliente</td>
            <td>Contenido</td>
            <td>Estado</td>
          </tr>
        </thead>
        <tbody>{renderUsers()}</tbody>
      </table>
    </div>
  );
};

export default UserCourseStatusTable;
