import React from "react";
import PurchaseRow from "./PurchaseRow";

const PurchasesTable = ({ user, purchases, showType, editExpiration }) => {
  const renderPurchases = () => {
    if (Array.isArray(purchases)) {
      if (purchases.length === 0) {
        return (
          <tr>
            <td colSpan={5}>No hay compras registradas.</td>
          </tr>
        );
      }
      return purchases.map((purchase) => (
        <PurchaseRow
          user={user}
          showType={showType}
          purchase={purchase}
          key={purchase.invoice_id ? purchase.invoice_id : purchase.purchase_id}
          editExpiration={editExpiration}
        />
      ));
    }
  };

  const renderCustomer = () => {
    if (!window.location.pathname.includes("customer/")) {
      return <td>Cliente</td>;
    }
  };

  const renderType = () => {
    if (showType) {
      return <td>Tipo</td>;
    }
  };

  return (
    <div className="table-responsive">
      <table className="table border">
        <thead className="bg-light border bold small">
          <tr>
            <td>#ID</td>
            {renderType()}
            {renderCustomer()}
            <td>Paquete</td>
            <td>Fecha</td>
            <td>Monto</td>
            <td>Pago</td>
            <td>Estado</td>
            <td>Descuento</td>
            <td>Acciones</td>
          </tr>
        </thead>
        <tbody>{renderPurchases()}</tbody>
      </table>
    </div>
  );
};

export default PurchasesTable;
