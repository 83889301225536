import React from "react";
import { Link } from "@reach/router";
import { S3_ENDPOINT } from "../../utils";

const PanelNavbar = ({ user, signOut }) => {
  const renderAnaliticas = () => {
    if (user.role === "admin") {
      return (
        <li className="nav-item dropdown">
          <a
            className="nav-link dropdown-toggle"
            href="#analiticasDropdown"
            role="button"
            data-bs-toggle="collapse"
            aria-expanded="false"
            aria-controls="analiticasDropdown"
          >
            Analiticas
          </a>
          <ul id="analiticasDropdown" className="collapse collapse-menu">
            <li className="nav-item text-item">
              <Link to="/myadmin/analytics/ingresos" className="nav-link">
                Ingresos
              </Link>
            </li>
            <li className="nav-item text-item">
              <Link to="/myadmin/analytics/inscritos" className="nav-link">
                Inscritos
              </Link>
            </li>
            <li className="nav-item text-item">
              <Link to="/myadmin/analytics/cursos" className="nav-link">
                Cursos
              </Link>
            </li>
            <li className="nav-item text-item">
              <Link to="/myadmin/analytics/mensuales" className="nav-link">
                Mensuales
              </Link>
            </li>
          </ul>
        </li>
      );
    }
  };
  return (
    <nav className="bg-dark hide-mobile side-menu">
      <div className="container-fluid px-0">
        <div className="px-3">
          <Link to="/" className="navbar-brand" href="#landing">
            <img
              src={`${S3_ENDPOINT}/latina-cert-logo.png`}
              className="mw-100 w-100"
            />
          </Link>
        </div>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="text-white">
          <ul className="side-menu-list">
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#clientesDropdown"
                role="button"
                data-bs-toggle="collapse"
                aria-expanded="false"
                aria-controls="clientesDropdown"
              >
                Clientes
              </a>
              <ul id="clientesDropdown" className="collapse collapse-menu">
                <li className="nav-item text-item">
                  <Link to="/myadmin/customers" className="nav-link">
                    Clientes
                  </Link>
                </li>
                <li className="nav-item text-item">
                  <Link to="/myadmin/orders" className="nav-link">
                    Compras
                  </Link>
                </li>
                <li className="nav-item text-item">
                  <Link to="/myadmin/customers/course/4" className="nav-link">
                    Instructoras
                  </Link>
                </li>
              </ul>
            </li>
            <li className="nav-item text-item">
              <Link to="/myadmin/courses" className="nav-link">
                Cursos
              </Link>
            </li>
            <li className="nav-item text-item">
              <Link to="/myadmin/instructors" className="nav-link">
                Instructores
              </Link>
            </li>
            <li className="nav-item text-item">
              <Link to="/myadmin/sections/4" className="nav-link">
                Tutorials
              </Link>
            </li>
            <li className="nav-item text-item">
              <Link to="/myadmin/sections/5" className="nav-link">
                Marketing
              </Link>
            </li>
            <li className="nav-item text-item">
              <Link to="/myadmin/sections/6" className="nav-link">
                Documents
              </Link>
            </li>
            <li className="nav-item text-item">
              <Link to="/myadmin/sections/7" className="nav-link">
                Music
              </Link>
            </li>
            <li className="nav-item text-item">
              <Link to="/myadmin/users" className="nav-link">
                Usuarios
              </Link>
            </li>
            {renderAnaliticas()}
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {String(user.name).substr(0, 20)}
                {String(user.name).length > 20 ? "..." : ""}
                <i className="ms-2 fa fa-user-circle"></i>
              </a>
              <ul
                className="dropdown-menu user-menu"
                aria-labelledby="navbarDropdown"
              >
                <li>
                  <button
                    className="dropdown-item text-danger"
                    onClick={signOut}
                  >
                    Salir <i className="ms-2 fa fa-sign-out-alt"></i>
                  </button>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default PanelNavbar;
