import React, { useContext } from "react";
import { FiltersContext } from "../../context/FiltersContext";
import FilterForm from "./FilterForm";

const FilterSection = ({ section_id }) => {
  const {
    level,
    query,
    author,
    values,
    setFilter,
    showFavorites,
    instructor_id,
  } = useContext(FiltersContext);

  const filters = {
    level,
    query,
    author,
    showFavorites,
    instructor_id,
  };

  return (
    <div className="filter-card card no-scale shadow-sm my-3">
      <FilterForm
        filters={filters}
        values={values}
        setFilter={setFilter}
        section_id={section_id}
      />
    </div>
  );
};

export default FilterSection;
