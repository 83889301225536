import React from "react";
/*
import { useContext } from "react";
import { CoursePurchaseContext } from "../../context/CoursePurchaseContext";
import EditCoursePurchaseForm from "./EditCoursePurchaseForm";
import { ModalContext } from "../../context/ModalContext";
import { UserContext } from "../../context/UserContext";
*/
import moment from "moment";

const CoursePurchaseRow = ({ customer_id, courses, course_purchase }) => {
  /*
  const { user } = useContext(UserContext);
  const { modalComponent } = useContext(ModalContext);

  const { setCoursePurchase } = useContext(CoursePurchaseContext);

  const handleEdit = () => {
    setCoursePurchase(course_purchase);
    modalComponent(
      "Editar Compra",
      <EditCoursePurchaseForm customer_id={customer_id} />
    );
  };

  const canEdit = () => {
    return ["admin", "manager"].includes(user.role);
  };

  const renderActions = () => {
    if (canEdit()) {
      return (
        <button
          className="btn btn-sm btn-outline-dark me-2"
          onClick={handleEdit}
        >
          <i className="fa fa-edit"></i>
        </button>
      );
    }
  };
  */

  const renderCourse = () => {
    if (course_purchase.name && course_purchase.name !== null) {
      return course_purchase.name;
    }
    if (course_purchase.course && course_purchase.course !== null) {
      return course_purchase.course.name;
    }
  };

  const renderExpiration = () => {
    if (
      course_purchase.has_access !== undefined &&
      course_purchase.expiration_date === undefined
    ) {
      const { has_access } = course_purchase;
      return (
        <span
          className={`badge badge-pill bg-${has_access ? "success" : "danger"}`}
        >
          {has_access ? "Activo" : "Inactivo"}
        </span>
      );
    }
    if (
      !course_purchase.expiration_date ||
      course_purchase.expiration_date === null
    ) {
      if (courses.includes(course_purchase.course_id)) {
        return <span className={`badge badge-pill bg-success`}>Activo</span>;
      }
      return <span className={`badge badge-pill bg-secondary`}>Inactivo</span>;
    }
    return moment(course_purchase.expiration_date)
      .utc()
      .format("DD MMM YYYY HH:mm");
  };

  return (
    <tr className="small border-bottom align-middle hover-light">
      <td>{renderCourse()}</td>
      <td>{renderExpiration()}</td>
    </tr>
  );
};

export default CoursePurchaseRow;
