import { Link } from "@reach/router";
import React from "react";

const UserCourseStatusRow = ({ user, course }) => {
  const { has_access } = user;
  return (
    <tr>
      <td>
        <Link to={`/myadmin/customer/${user.user_id}`}>
          {user.name} {user.last_name !== null ? user.last_name : ""}
        </Link>
      </td>
      <td>{course.name}</td>
      <td>
        <span
          className={`badge badge-pill ${
            has_access ? "bg-success" : "bg-danger"
          }`}
        >
          {has_access ? "Activo" : "Inactivo"}
        </span>
      </td>
    </tr>
  );
};

export default UserCourseStatusRow;
