import React, { useContext, useEffect, useState } from "react";
import PanelTitleDate from "../../components/global/PanelTitleDate";
import { PurchasesContext } from "../../context/PurchasesContext";
import Pagination from "../../components/global/Pagination";
import PurchasesTable from "../../components/purchases/PurchasesTable";
import { UserContext } from "../../context/UserContext";
import { CoursesContext } from "../../context/CoursesContext";

const AdminPurchases = () => {
  const [page, setPage] = useState(1);
  const [type, setType] = useState(null);
  const [query, setQuery] = useState("");
  const [status, setStatus] = useState("");
  const [endDate, setEndDate] = useState("");
  const [startDate, setStartDate] = useState("");
  const [courseId, setCourseId] = useState(null);

  const { spinner, purchases, getPurchases } = useContext(PurchasesContext);

  const { courses, getCourses } = useContext(CoursesContext);

  const { user } = useContext(UserContext);

  useEffect(() => {
    getCourses();
  }, []);

  useEffect(() => {
    if (startDate !== "" && endDate !== "" && !spinner) {
      fetchPurchases();
    }
  }, [startDate, endDate, courseId, page, status, query]);

  const fetchPurchases = () => {
    getPurchases(startDate, endDate, {
      page,
      status,
      query,
      course_id: courseId,
    });
  };

  const setDates = (start, end) => {
    setStartDate(start);
    setEndDate(end);
  };

  const filterPurchases = () => {
    let result = purchases;
    if (Array.isArray(purchases)) {
      switch (type) {
        case "purchase":
          result = purchases.filter((purchase) => !purchase.invoice_id);
          break;
        case "invoice":
          result = purchases.filter((purchase) => purchase.invoice_id);
          break;
      }
    }
    return result;
  };

  const renderCourses = () => {
    if (Array.isArray(courses)) {
      return [
        <option value="">Todos los Cursos</option>,
        ...courses.map((course) => (
          <option key={course.course_id} value={course.course_id}>
            {course.name}
          </option>
        )),
      ];
    }
  };

  return (
    <div className="container-fluid">
      <PanelTitleDate title="Pagos" callback={setDates} />
      <div className="container-fluid px-0">
        <input
          type="text"
          value={query}
          className="form-control mb-3"
          onChange={(e) => setQuery(e.target.value)}
          placeholder="Buscar por nombre o correo electrónico..."
        />
      </div>
      <div className="card no-scale p-3 mb-3 shadow-sm">
        <div className="row align-items-center mb-3">
          <div className="col-12 col-md-6">
            <button
              className={`btn btn-sm btn-${
                status === "" ? "primary" : "light"
              } border br-0`}
              onClick={() => setStatus("")}
            >
              Todo
            </button>
            <button
              className={`btn btn-sm btn-${
                Array.isArray(status) && status.includes("active")
                  ? "primary"
                  : "light"
              } border br-0`}
              onClick={() => setStatus(["active", "completed", "success"])}
            >
              Activos
            </button>
            <button
              className={`btn btn-sm btn-${
                Array.isArray(status) && status.includes("cancelled")
                  ? "primary"
                  : "light"
              } border br-0`}
              onClick={() => setStatus(["cancelled", "failed", "pending"])}
            >
              Cancelados
            </button>
            <button
              className={`btn btn-sm btn-${
                Array.isArray(status) && status.includes("processing")
                  ? "primary"
                  : "light"
              } border br-0`}
              onClick={() => setStatus(["processing", "on-hold"])}
            >
              Pendientes
            </button>
          </div>
          <div className="col-12 col-md-3">
            <button
              className={`btn btn-sm btn-${
                type === null ? "primary" : "light"
              } border br-0`}
              onClick={() => setType(null)}
            >
              Todos
            </button>
            <button
              className={`btn btn-sm btn-${
                type === "purchase" ? "primary" : "light"
              } border br-0`}
              onClick={() => setType("purchase")}
            >
              Compras
            </button>
            <button
              className={`btn btn-sm btn-${
                type === "invoice" ? "primary" : "light"
              } border br-0`}
              onClick={() => setType("invoice")}
            >
              Cargos
            </button>
          </div>
          <div className="col-12 col-md-3">
            <select
              value={courseId}
              className="form-control"
              onChange={(e) => setCourseId(e.target.value)}
            >
              {renderCourses()}
            </select>
          </div>
        </div>
        <PurchasesTable purchases={filterPurchases()} user={user} showType />
        <Pagination modifier={setPage} />
      </div>
    </div>
  );
};

export default AdminPurchases;
