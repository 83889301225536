import { SET_COURSE_PURCHASE } from "../types";

const CoursePurchaseReducer = (state, { type, payload }) => {
  switch (type) {
    case SET_COURSE_PURCHASE:
      return { ...state, course_purchase: payload };
    default:
      return { ...state };
  }
};
export default CoursePurchaseReducer;
