import React from "react";
import CoursePurchaseRow from "./CoursePurchaseRow";

const CoursePurchasesTable = ({ customer_id, courses, course_purchases }) => {
  const renderPurchases = () => {
    if (Array.isArray(courses)) {
      if (courses.length === 0) {
        return (
          <tr>
            <td colSpan={5}>No hay cursos registradas.</td>
          </tr>
        );
      }
      return courses.map((course_purchase) => (
        <CoursePurchaseRow
          customer_id={customer_id}
          courses={course_purchases}
          key={course_purchase.course_id}
          course_purchase={course_purchase}
        />
      ));
    }
  };

  const renderCustomer = () => {
    if (!window.location.pathname.includes("customer/")) {
      return <td>Cliente</td>;
    }
  };

  return (
    <div className="table-responsive">
      <table className="table border">
        <thead className="bg-light border bold small">
          <tr>
            <td>Curso</td>
            <td>Acceso</td>
          </tr>
        </thead>
        <tbody>{renderPurchases()}</tbody>
      </table>
    </div>
  );
};

export default CoursePurchasesTable;
